@use '../variables';

.input__simple {

    input[type='text'],
    input[type='search'],
    input[type='tel'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='time'],
    textarea,
    select,
    .select2-container--default .select2-selection--multiple,
    .select2-container--default .select2-selection--single,
    .input-autocomplete .fakeinput {
        font-family: variables.$font-family-normal;
        border: none;
        border-bottom: 1px solid variables.$gray_blue_light;
        background: none;
        background-color: transparent;
        border-radius: 0;
        font-size: 16px;
        padding: 0;
        min-height: 20px;
        min-width: 20px;
        width: 100%;
        transition: box-shadow 0.3s ease;
        color: variables.$medium_teal;

        &:focus {
            box-shadow: none;
            border-color: variables.$blue_medium;
            border-bottom: 1px solid variables.$blue_medium;
        }

        &[disabled] {
            background-color: none;
        }

        &[disabled] {
            background-color: transparent;
            background-image: url('images/icon-lock.svg');
            background-position: right 10px top 6px;
            background-repeat: no-repeat;
        }
    }

    .fakeinput {
        height: 40px;
        display: flex;
        align-items: center;
    }

    input[type='text'],
    input[type='search'],
    input[type='tel'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='time'],
    textarea,
    select,
    .select2-container--default .select2-selection--multiple,
    .select2-container--default .select2-selection--single,
    .fakeinput {
        font-family: variables.$font-family-normal;
        border: none;
        border-bottom: 1px solid variables.$gray_blue_light;
        // background: none;
        background-color: transparent;
        border-radius: 0;
        font-size: 18px;
        padding: 4px 0px;
        min-height: 20px;
        width: 100%;
        transition: box-shadow 0.3s ease;
        color: variables.$medium_teal;

        &:focus {
            box-shadow: none;
            border-color: variables.$blue_medium;
            border-bottom: 1px solid variables.$blue_medium;
        }

        &[disabled] {
            background-color: transparent !important;
        }

        &[disabled] {
            padding-left: 20px;
            background-color: transparent !important;
            background-image: url('images/icon-lock.svg');
            background-position: left 0px top 6px;
            background-repeat: no-repeat;
            background-size: 14px;
        }
    }

    .form-element {
        margin-bottom: 1em;

        label {
            display: block;
            font-weight: 600;
            font-size: 0.85rem;
            color: variables.$ocean_black;
            opacity: 0.85;
            margin-bottom: 0px;
        }
    }

    datepicker input[type='text'] {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMjAgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5LjEgKDUxMTQ3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5jYWxlbmRhcjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxnIGlkPSJkYXRlX3BpY2tlcl9ub25lLXNlbGVjdGVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTUwLjAwMDAwMCwgLTcuMDAwMDAwKSIgc3Ryb2tlPSIjNDM3QzhEIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0iY2FsZW5kYXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1MS4wMDAwMDAsIDguMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlLXBhdGgiIHg9IjAiIHk9IjIiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjIiPjwvcmVjdD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMywwIEwxMyw0IiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik01LDAgTDUsNCIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMCw4IEwxOCw4IiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
        background-position: right 10px top 0px;
        background-repeat: no-repeat;
    }

    .select2-container--default.select2-container--disabled .select2-selection--single {
        background-color: transparent;
        cursor: default;
        padding-left: 20px;
        background-image: url('images/icon-lock.svg');
        background-position: left 0px top 6px;
        background-repeat: no-repeat;
        background-size: 14px;
    }

    .select2-container--default .select2-selection--single {
        background-color: transparent !important;
        border: none !important;
        color: variables.$medium_teal !important;
        border-bottom: 1px solid variables.$gray_blue_light !important;
        border-radius: 0px !important;

        &:focus {
            box-shadow: none !important;
            border-color: variables.$blue_medium !important;
            border-bottom: 1px solid variables.$blue_medium !important;
        }
    }

    .select2-container .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: 28px;
        user-select: none;
        -webkit-user-select: none;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .fakeinput {
        line-height: 1;

        &__wrapper {
            position: relative;
        }

        &.autocomplete-selected {
            background: transparent;
        }

        &__clear {
            padding: 5px 8px;

            &:hover {
                background: #cbdca9;
            }
        }
    }

    select,
    .select2-container--default .select2-selection--multiple,
    .select2-container--default .select2-selection--single {
        background-position: right 14px top 10px;
    }

    fieldset {
        &:disabled {

            input[type='text'],
            input[type='search'],
            input[type='tel'],
            input[type='email'],
            input[type='number'],
            input[type='password'],
            input[type='time'],
            textarea,
            select,
            .select2-container--default .select2-selection--multiple,
            .select2-container--default .select2-selection--single,
            .fakeinput {
                background-color: variables.$gray_blue_lighter;
            }

            input[type='text'],
            input[type='search'],
            input[type='tel'],
            input[type='email'],
            input[type='number'],
            input[type='password'],
            input[type='time'],
            textarea {
                padding-left: 20px;
                background-image: url('images/icon-lock.svg');
                background-position: left 0px top 6px;
                background-repeat: no-repeat;
                background-size: 14px;
                background-color: variables.$gray_blue_lighter;
            }
        }

        border: none;
        padding: 0 !important;
        margin: 0;
    }

    textarea {
        background-color: variables.$zebra_light;
        padding: 6px;
    }
}
