@use "../variables";

.list-validation {
    margin-bottom: variables.$spacing-4;

    &--heading {
        font-weight: bold;
    }

    &--error,
    &--success,
    &--warning {
        margin-left: 0.75rem;
    }

    &--error {
        color: variables.$approved_red;
    }

    &--success {
        color: variables.$approved_green;
    }

    &--warning {
        color: variables.$approved_yellow;
    }
}
