@use '../variables';

.container {
  max-width: 1280px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;

  //margin: 0 -10px;
  .col {
    //margin: 0 10px;
    flex: 1;

    &--grow {
      flex: 2;
    }

    &--separate {
      margin-left: 2 * variables.$app_default_padding_horizontal;
      padding-left: 2 * variables.$app_default_padding_horizontal;
      border-left: 1px solid variables.$foaming_grey;

      @media (max-width: variables.$xs) {
        margin: 0;
        padding: 0;
        border: none;
      }
    }
  }

  .col1of4 {
    flex: 0 0 24%;
    max-width: 24%;
  }

  .col2of4 {
    flex: 0 0 49%;
    max-width: 49%;
  }

  .col3of4 {
    flex: 0 0 74%;
    max-width: 74%;
  }

  > .row {
    width: 100%;
  }

  &--biggutter {
    .col {
      margin-right: 10%;

      @media (max-width: variables.$xs) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: variables.$xs) {
    display: block;
  }
}

.row--guttered {
  justify-content: space-between;

  .col2of3 {
    flex: 2;
  }

  .col2-3of3 {
    flex: 2.2;
  }

  .col {
    margin-right: variables.$app_default_padding_horizontal;

    @media (max-width: variables.$xs) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin gris-cols {
  &--one-cols {
    grid-template-columns: 1fr;
  }

  &--two-cols {
    grid-template-columns: 1fr 1fr;
  }

  &--three-cols {
    grid-template-columns: repeat(3, 1fr);
  }

  &--four-cols {
    grid-template-columns: repeat(4, 1fr);
  }
}

@mixin xs {
  &-xs {
    @media (min-width: 0px) {
      @content;
    }
  }
}

@mixin sm {
  &-sm {
    @media (min-width: variables.$xxs) {
      @content;
    }
  }
}

@mixin md {
  &-md {
    @media (min-width: variables.$xs) {
      @content;
    }
  }
}

.row-grid {
  display: grid;
  grid-gap: 32px;
  row-gap: 32px;

  &__col {
    &--preview {
      text-align: right;
    }
  }

  @include gris-cols;

  @include xs {
    @include gris-cols;
  }

  @include sm {
    @include gris-cols;
  }

  @include md {
    @include gris-cols;
  }
}

.flex-container {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-gapped {
  display: flex;
  gap: 0.5rem;
}

@mixin col-gap-classes {
  @for $i from 0 through 6 {
    .col-gap-#{$i} {
      column-gap: #{$i * 4}px;
    }

    .row-gap-#{$i} {
      row-gap: #{$i * 4}px;
    }

    .gap-#{$i} {
      gap: #{$i * 4}px;
    }
  }
}

@include col-gap-classes;

.flex {
  display: flex;

  &--direction--col {
    flex-direction: column;
  }

  &--direction--row {
    flex-direction: row;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-center {
    justify-content: center;
  }

  &--align-center {
    align-items: center;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-end {
    align-items: flex-end;
  }
}
