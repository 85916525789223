@use "../variables";

.analysis-variant__factors {
  display: flex;
  gap: 2rem;

  @media (max-width: variables.$large) {
    flex-direction: column;
    gap: 0;
  }

  > div {
    width: 100%;
  }

  h3 {
    width: 100%;
  }

  .analysis-variant__factors-section {
    display: flex;
    gap: 1rem;

    @media (max-width: variables.$s) {
      flex-direction: column;
      gap: 0;
    }

    .form-element {
      width: 100%;
    }
  }
}
.analysis-load-demand {
  margin-bottom: 1rem;
  display: flex;
  gap: 2rem;

  &__label {
    width: 150px;
  }

  &__input {
    width: 25%;
  }

  .te {
    display: flex;
  }

  .te2 {
    display: flex;
    width: 100%;
    gap: 2rem;
  }

  @media (max-width: variables.$large) {
    flex-wrap: wrap;
    gap: 0;

    &__input {
      width: 50%;
    }
  }

  @media (max-width: variables.$s) {
    .te2 {
      flex-wrap: wrap;
      gap: 0;
    }
    &__input {
      width: 100%;
    }
  }
}
