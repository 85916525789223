@use 'sass:color';
/*----------  flexbox simple grid  ----------*/
$large: 1439px;
$b_large: 1440px;

$m: 1200px;
$b_m: 1201px;

$s: 959px;
$b_s: 960px;

$xs: 768px;
$b_xs: 769px;

$xxs: 540px;
$b_xxs: 541px;

// Blues
$blue_subtle_light: #f2f5fa;

$blue_dark: #0e4959;
$blue_dark_l5: #125668;
$blue_dark_d5: #003e52;
$blue_dark_d10: #003444;
$blue_dark_d20: #06232d;

$blue_medium: #437c8d;
$blue_medium_hover: #54aec9;

$blue_light: #a8c6ce;

// new colors:

$ocean_black: #06232d;

$dark_teal: #003e52;

$medium_teal: #0e4959;

$teal: #437c8d;

$light_teal: #a8c6ce;

$teal_grey: #d6e1e5;

$cloudy_grey: #ced4da;

$buoy_red: #c22927;
$deep_orange: #ff7f3e;
$light_orange: #ffd27f;
$soft_red: #c86c6c;
$soft_red_light: color.adjust($soft_red, $lightness: 15%);
$soft_red_dark: color.adjust($soft_red, $lightness: -10%);

$salmon_pink: #ff917c;

$net_green: #83c8bc;
$sea_grass_green: #c3dc93;
$foaming_grey: #e8eced;
$canvas_white: #fbfcfc;
$pearl_grey: #f7f8f9;
$boring_grey: #767878;

$white: #fff;

$venture_purple: #a886c6;
$jumpnet_blue: #22b2ca;

$service_blue: #4a90e2;

$service_order_green: #bff08d;
$pastel_green: #71c776;

// approvals
$approved_green: #7ed321;
$approved_yellow: #ffa500;
$approved_red: #c22927;

// Grays
$gray_main: #ebebeb;
$gray_light: #eaeaea;
$gray_lighter: #f8f8f8;
$gray_lightest: #fcfcfc;
// border: 1px solid #dbdbdb;

$gray_blue_lighter: #e9ecef;
$gray_blue_light: #ced4da;

$zebra_light: #eff2f3;
$gray_zebra: $zebra_light;
$gray_vertical_lines: #d8d8d8;

$orange: #ff917c;
$cyan: #83c8bc;

/*=============================================
=                    App                     =
=============================================*/

$app_default_padding_horizontal: 30px;
$app_default_padding_vertical: 30px;

$app_sidebar_padding_horizontal: 22px;
$app_sidebar_padding_vertical: 22px;

$app_default_header_height: 75px;

$border-radius: 6px;
$font-family-normal: 'Open Sans', sans-serif;
$font-family-title: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$spacing-1: 2px;
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 16px;
$spacing-5: 24px;

/*=============================================
=                    Grid                     =
=============================================*/
$soft_shadow: 0 2px 0.2em 0 #0000000f;
$default_shadow: 0px 4px 8px rgb(86 86 86 / 17%);

.create-offer-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.8);
        z-index: 11;
        animation: fadeIn 0.5s ease;
    }

    &__inner {
        position: absolute;
        box-shadow: 0 6px 11px rgba(0, 0, 0, 0.4);
        top: 50%;
        left: 50%;
        width: 90%;
        z-index: 12;
        padding: 30px;
        border-radius: 5px;

        h3 {
            margin-top: 0;
        }

        max-width: 1200px;
        transform: translate(-50%, -50%);
        background-color: $blue_dark;
        max-height: 95vh;
        overflow: auto;
        color: $white;

        .form-element label,
        .form-element--required:before {
            color: $white;
        }

        animation: bringUpFromBelow 0.5s ease;
        transform-origin: 0% 0%;

        &__cols {
            display: grid;
            grid-template-columns: 70% 30%;

            &__right {
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &__bottom {
                    text-align: right;
                }
            }
        }

        input[type='text'],
        input[type='search'],
        input[type='tel'],
        input[type='email'],
        input[type='number'],
        input[type='password'],
        textarea,
        select,
        .select2-container--default .select2-selection--multiple,
        .select2-container--default .select2-selection--single,
        .fakeinput {
            background-color: rgba($white, 0.5);
            border-color: transparent;
            transition: all 0.3s ease;

            &:hover,
            &:focus {
                background-color: $white;
                border-color: transparent;
            }
        }

        .fakeinput {
            color: $blue_dark_d20;
        }
    }
}

.design-type-selector {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    list-style: none;
    margin: 0;
    padding: 10px 0;

    &__item {
        padding: 20px;
        text-align: center;
        font-size: 14px;
        height: 100%;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 5px;

        &:hover {
            background-color: $blue_dark_d20;
        }

        &--selected {

            &,
            &:hover {
                background-color: color.adjust($blue_dark, $lightness: 10%);
            }
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &__title {
            margin: 0;
            font-size: 14px;
        }

        &__icon {
            max-width: 70px;
            max-height: 70px;
            height: auto;
            width: auto;
        }
    }
}

.net-is-combi-net,
.net-is-collect-net {
    display: block;
    padding: 9px 1rem;
    border: 1px solid black;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: color.adjust($blue_dark, $lightness: 10%);
    }
}

.close-button {
    width: 40px;
    height: 30px;
    border: 1px solid transparent;
    background-color: transparent;
    background-image: url(../../images/icon-close.svg);
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s ease;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
        border: 1px solid white;
    }
}

@keyframes bringUpFromBelow {
    from {
        opacity: 0;
        top: 52%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}
