fieldset {
    &:disabled {

        input[type='text'],
        input[type='search'],
        input[type='tel'],
        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='time'],
        textarea,
        select,
        .select2-container--default .select2-selection--multiple,
        .select2-container--default .select2-selection--single,
        .fakeinput {
            background-color: #e9ecef !important;
        }
    }

    border: none;
    padding: 0 !important;
    margin: 0;
}
