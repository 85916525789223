ux-dialog {
    background: #fbfbfb !important;

    &.ui-dialog {
        position: relative;
    }

    .ui-dialog__close {
        background: none;
        border: none;
        position: absolute;
        top: 16px;
        right: 10px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
            opacity: 0.7;
        }
    }

    &.small {
        width: 98vw;
        max-width: 600px;
    }

    &.medium {
        width: 98vw;
        max-width: 800px;
    }

    &.large {
        width: 98vw;
        max-width: 1200px;
    }

    &.xl {
        width: 98vw;
        max-width: 1900px;
    }

    &.preview {
        width: 98vw;
        max-width: 1200px;
        height: calc(100vh - 80px);
        margin: -1rem;

        ux-dialog-body {
            padding: 0;
        }
    }
}
