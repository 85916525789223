@use "../variables";

.mn-tabs {
    list-style: none;
    margin: 0;
    padding: 0;

    .mn-tabs-list {
        border-bottom: 1px solid variables.$blue_light;
    }

    .mn-tab {
        padding: 6px 12px;
        margin: 0;
        border: 0;
        background: none;
        color: variables.$blue_medium;
        font-weight: 400;
        text-transform: uppercase;
        cursor: pointer;
        transition: color 0.3s;
        margin-bottom: -1px;
        display: inline-block;

        &:hover {
            color: variables.$blue_dark;
        }

        &.active {
            color: variables.$blue_dark;
            border-bottom: 2px solid variables.$blue_dark;
        }
    }

    .mn-tab-content {
        display: none;
        font-weight: 600;
        padding: 12px 0;
    }

    .mn-tab-content.active {
        display: block;
    }
}
