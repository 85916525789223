@use 'sass:color';
/*----------  flexbox simple grid  ----------*/
$large: 1439px;
$b_large: 1440px;

$m: 1200px;
$b_m: 1201px;

$s: 959px;
$b_s: 960px;

$xs: 768px;
$b_xs: 769px;

$xxs: 540px;
$b_xxs: 541px;

// Blues
$blue_subtle_light: #f2f5fa;

$blue_dark: #0e4959;
$blue_dark_l5: #125668;
$blue_dark_d5: #003e52;
$blue_dark_d10: #003444;
$blue_dark_d20: #06232d;

$blue_medium: #437c8d;
$blue_medium_hover: #54aec9;

$blue_light: #a8c6ce;

// new colors:

$ocean_black: #06232d;

$dark_teal: #003e52;

$medium_teal: #0e4959;

$teal: #437c8d;

$light_teal: #a8c6ce;

$teal_grey: #d6e1e5;

$cloudy_grey: #ced4da;

$buoy_red: #c22927;
$deep_orange: #ff7f3e;
$light_orange: #ffd27f;
$soft_red: #c86c6c;
$soft_red_light: color.adjust($soft_red, $lightness: 15%);
$soft_red_dark: color.adjust($soft_red, $lightness: -10%);

$salmon_pink: #ff917c;

$net_green: #83c8bc;
$sea_grass_green: #c3dc93;
$foaming_grey: #e8eced;
$canvas_white: #fbfcfc;
$pearl_grey: #f7f8f9;
$boring_grey: #767878;

$white: #fff;

$venture_purple: #a886c6;
$jumpnet_blue: #22b2ca;

$service_blue: #4a90e2;

$service_order_green: #bff08d;
$pastel_green: #71c776;

// approvals
$approved_green: #7ed321;
$approved_yellow: #ffa500;
$approved_red: #c22927;

// Grays
$gray_main: #ebebeb;
$gray_light: #eaeaea;
$gray_lighter: #f8f8f8;
$gray_lightest: #fcfcfc;
// border: 1px solid #dbdbdb;

$gray_blue_lighter: #e9ecef;
$gray_blue_light: #ced4da;

$zebra_light: #eff2f3;
$gray_zebra: $zebra_light;
$gray_vertical_lines: #d8d8d8;

$orange: #ff917c;
$cyan: #83c8bc;

/*=============================================
=                    App                     =
=============================================*/

$app_default_padding_horizontal: 30px;
$app_default_padding_vertical: 30px;

$app_sidebar_padding_horizontal: 22px;
$app_sidebar_padding_vertical: 22px;

$app_default_header_height: 75px;

$border-radius: 6px;
$font-family-normal: 'Open Sans', sans-serif;
$font-family-title: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$spacing-1: 2px;
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 16px;
$spacing-5: 24px;

/*=============================================
=                    Grid                     =
=============================================*/
$soft_shadow: 0 2px 0.2em 0 #0000000f;
$default_shadow: 0px 4px 8px rgb(86 86 86 / 17%);

/* Style the table container */
//
.dynamic-table {
  display: block;

  .inline-entity__form {
    width: 100%;
  }

  &__table-row {
    border-top: 1px solid $blue_light;
    border-bottom: 2px solid $blue_medium;
    text-align: left;
    font-size: 14px;
    color: $medium_teal;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
  }

  &__table-header {
    font-weight: bold;
  }

  &__table-cell {
    flex: 1;
    padding: 8px;
    text-align: left;

    &:last-child:not(:first-child) {
      padding-right: 12px;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 8px;

      text-align: right;
      > * {
        width: fit-content;
        text-align: left;
      }

      &.text-centered {
        text-align: center;
      }
    }
  }
  &__table-cell__body {
    display: flex;
    align-items: center;
  }
}
