@use '../variables';
@use 'sass:color';

/*----------  Layout  ----------*/
.app {
  height: 100%;
}

html,
body {
  min-height: 100vh;
  height: 100%;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: variables.$canvas_white;
  font-family: variables.$font-family-normal;
  line-height: 1.5;
  color: variables.$medium_teal;
  scroll-behavior: smooth;
  font-size: 16px;
}

router-view {
  width: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    flex-direction: row;
    transition: transform 0.4s ease;

    &.sidebarRightVisible {
      transform: translateX(-200px);
    }

    &.sidebarLeftVisibleMobile {
      .app__sidebar--left {
        transform: translateX(0);
      }
    }
  }

  &__header {
    background-color: variables.$blue_dark;
    //background: linear-gradient(to bottom, $blue_dark 0%, $blue_dark_d10 100%);
    min-height: 75px;
  }

  &__header__desktop-menu {
    img {
      margin-right: 15px;
    }

    button {
      width: 240px;
      display: flex;
      flex-direction: row;
      align-items: center;

      background-color: variables.$dark_teal;
      border: 0;
      box-shadow: none;
      transition: background-color 0.2s ease;
      outline: none;
      padding-left: 25px;
      padding-right: 25px;

      &:hover {
        background-color: variables.$blue_dark_d10;
      }
    }

    p {
      color: variables.$white;
    }
  }

  &__sidebar {
    // background-color: $foaming_grey;
    background-color: variables.$blue_dark_d5;
    flex: 0 0 240px;
    position: relative;
    z-index: 1;

    &__keep-open-checkbox {
      padding: 32px 22px;

      label {
        color: variables.$light_teal !important;
        font-weight: 500 !important;
        opacity: 0.9;
      }
    }

    &--closed {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      min-width: 200px;
      transform: translateX(-100%);
      z-index: 2;
    }
  }

  &__content {
    overflow-y: auto;
    display: flex;
    flex: 1;
    //background: hotpink;
  }

  &__sidebar--right {
    position: fixed;
    top: 0;
    bottom: 0px;
    min-height: 100vh;
    height: 100%;
    right: 0;
    width: 200px;
    transform: translateX(200px);
  }

  &__viewports {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
}

@media (max-width: variables.$xs) {
  .app__header__desktop-menu {
    display: none;
  }

  .app {
    &__wrapper {
      position: relative;
    }

    &__sidebar--left {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 200px;
      transform: translateX(-200px);
      z-index: 2;
      transition: transform 0.2s ease;
    }
  }
}

router-view {
  display: block;
}

router-view[name='main'] {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

router-view[name='side'] {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40vw;
  z-index: 1;
  background: variables.$foaming_grey;
  overflow-y: auto;
  transition: transform 0.2s ease;
  transform: translateX(40vw);
  box-shadow: 0 0 0 transparent;

  &.visible {
    transform: translateX(0);
    box-shadow:
      0px 0px 1px rgba(0, 0, 0, 0.62),
      -1px 0px 8px rgba(142, 150, 152, 0.52);
    overflow-y: auto;
    position: fixed;
  }

  .app__content__header {
    background: variables.$foaming_grey;
    box-shadow: 0 1px 0 variables.$white;
    align-items: flex-start;
  }
}

.app__header {
  display: flex;
  align-items: center;
  color: variables.$white;

  &__logo a,
  &__person a,
  &__menu-left a {
    height: variables.$app_default_header_height;
    display: flex;
    justify-content: center;
    align-items: center;
    color: variables.$white;

    &:hover {
      background-color: variables.$blue_dark_d10;
    }

    img,
    span {
      pointer-events: none;
    }
  }

  &__logo,
  &__menu-left {
    background-color: variables.$dark_teal;

    a {
      width: variables.$app_default_header_height;
    }
  }

  &__menu-left {
    display: none;

    @media (max-width: variables.$xs) {
      display: flex;
    }
  }

  &__logo {
    @media (max-width: variables.$xs) {
      display: none;
    }
  }

  &__title {
    padding-left: variables.$app_default_padding_horizontal;
    padding-right: variables.$app_default_padding_horizontal;
    margin: 0;
    flex: 1;

    @media (max-width: variables.$xs) {
      font-size: 20px;
    }
  }

  &__person {
    background-color: variables.$blue_dark_l5;
    width: 200px;

    a {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
    }

    img {
      margin-right: 10px;
    }

    @media (max-width: variables.$xs) {
      width: 50px;

      img {
        margin-right: 0;
      }

      .text {
        display: none;
      }
    }
  }

  &__search {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-right: 20px;
    border-radius: 50px;

    &__inputwrap {
      max-width: 0;
      overflow: hidden;
      transition: all 0.3s ease;
      margin-right: 25px;
    }

    &.expanded {
      .app__header__search__inputwrap {
        max-width: 300px;
      }
    }

    @media (max-width: variables.$xs) {
      margin-right: 9px;

      &.expanded {
        position: absolute;
        left: 84px;
        width: calc(100% - 143px);
        margin-right: 0;

        .app__header__search__inputwrap {
          max-width: initial;
          width: 100%;
        }
      }
    }

    input[type='text'] {
      border: none;
      background-color: variables.$dark_teal;
      padding: 17px;
      padding-right: 60px;
      font-size: 18px;
      color: variables.$white;

      &::placeholder {
        color: variables.$light_teal;
      }
    }

    &__icon {
      position: absolute;
      right: 0;
      top: 0px;
      background-color: variables.$dark_teal;
      border-radius: 50%;
      width: 54px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      img {
        transition: transform 0.2s ease;
      }

      &:hover {
        cursor: pointer;

        img {
          transform: scale(1.2);
        }
      }

      &--results-visible {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &__results {
      position: absolute;
      background: variables.$white;
      box-shadow:
        rgba(0, 0, 0, 0.24) 0px 0px 1px,
        rgba(203, 218, 222, 0.52) -1px 0px 8px;
      top: 0;
      left: 0;
      z-index: 2;
      top: 54px;
      left: 8px;
      width: 292px;
    }
  }
}

.app__sidebar {
  .menu {
    &__li {
    }

    &__item {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding: 17px variables.$app_sidebar_padding_horizontal;
      color: variables.$medium_teal;
      text-transform: uppercase;
      position: relative;
      font-size: 15px;
      transition: all 0.1s ease;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: transparent;
        width: 0;
        transition: all 0.1s ease;
      }

      &:hover {
        background-color: variables.$blue_medium;
        color: variables.$white;
        overflow: visible;
        text-overflow: initial;
        padding-right: 0;

        &:after {
          right: -40px;
        }

        &:before {
          background-color: variables.$blue_medium;
          width: 30px;
          right: -30px;
        }
      }

      &--active {
        background-color: variables.$blue_light;
        color: white;
        position: relative;

        &:after {
          position: absolute;
          right: 0;
          content: '';
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid variables.$white;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &--right {
    background: variables.$medium_teal;

    &,
    .menu__item {
      color: variables.$white;
    }

    .menu__item {
      padding: 10px variables.$app_sidebar_padding_horizontal;
      text-transform: none;

      &:hover {
        background-color: variables.$blue_dark_d10;
      }

      &.active-language {
        background: color.adjust(variables.$blue_medium, $lightness: -10%);
      }
    }

    .label {
      margin: variables.$app_sidebar_padding_horizontal 0 0;
    }

    .language-switcher {
      border-bottom: 1px solid variables.$blue_medium;
    }
  }

  &__pad-horizontal {
    padding-left: variables.$app_sidebar_padding_horizontal;
    padding-right: variables.$app_sidebar_padding_horizontal;
  }
}

.app__content {
  &__header {
    background: none;
    // box-shadow:
    //     0 0 1px rgba(black, 0.1),
    //     0 0 10px rgba(black, 0.25);
    padding: 15px 30px;
    display: flex;
    min-height: 56px;
    border-bottom: 1px solid variables.$gray_main;

    @media (max-width: variables.$xs) {
      padding: 9px calc(variables.$app_default_padding_horizontal / 2);
      display: block;
    }

    &--withsub {
      padding: 0;
      flex-direction: column;
    }

    &__top {
      display: flex;
      flex: 1;
      border-bottom: 1px solid variables.$gray_light;
      padding: 9px variables.$app_default_padding_horizontal;
      min-height: 57px;

      @media (max-width: variables.$xs) {
        padding: 9px calc(variables.$app_default_padding_horizontal / 2);
      }
    }

    &__bottom {
      border-bottom: 1px solid variables.$gray_light;
      padding: 16px variables.$app_default_padding_horizontal;

      > div {
        p {
          margin: 0.2em 0;

          &.header-label-bold {
            font-weight: 600;
            font-size: 1.3rem;
          }
        }
      }

      @media (max-width: variables.$xs) {
        padding: 9px calc(variables.$app_default_padding_horizontal / 2);
      }
    }

    &__title {
      flex: 1;
      display: flex;
      align-items: center;
      text-transform: uppercase;

      @media (max-width: variables.$xs) {
        display: none;
      }
    }

    h2 {
      margin: 0;

      &:not[slot='badge'] {
        font-size: 1.5rem;
      }
    }
  }

  main {
    padding: variables.$app_default_padding_vertical variables.$app_default_padding_horizontal;
    display: block;

    &.main--with-navbar {
      padding-bottom: 100px;
    }

    .tabs {
      margin: -#{variables.$app_default_padding_vertical} -#{variables.$app_default_padding_horizontal};
      margin-bottom: variables.$app_default_padding_vertical;
    }

    @media (max-width: variables.$xs) {
      padding: calc(variables.$app_default_padding_vertical / 2) calc(variables.$app_default_padding_horizontal / 2);

      .tabs {
        margin: -(calc(variables.$app_default_padding_vertical / 2)) (-(calc(variables.$app_default_padding_horizontal / 2)));
        margin-bottom: calc(variables.$app_default_padding_vertical / 2);
      }
    }
  }
}
