@use "../variables";

.form-element {
    .label-wrapper {

        &__label,
        &__description {
            display: inline-block;
        }

        &__description {
            margin-left: 10px;
            color: variables.$boring_grey;
            font-style: italic;
        }
    }

    &--has-error {

        input[type='text'],
        input[type='number'],
        select,
        .select2-container--default .select2-selection--multiple,
        .select2-container--default .select2-selection--single {
            border-color: variables.$buoy_red !important;
        }

        .help-block {
            color: variables.$buoy_red;
        }

        .warning-block {
            color: variables.$orange;
        }
    }

    &--statuses-list {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        li {
            width: 33.33%;
        }
    }

    &__helper-text {
        position: absolute;
        display: block;
        bottom: -18px;
        font-size: 0.75rem;
    }

    &>.form-element {
        margin-bottom: 0;
    }
}

.form-element {
    margin-bottom: 1em;
    position: relative;

    &__input-prefix {
        position: absolute;
        bottom: 10px;
        left: 10px;
        z-index: 1;
        color: variables.$teal;
        pointer-events: none;

        +numeric-input input {
            padding-left: 25px;
        }
    }

    .unit {
        pointer-events: none;
        color: variables.$foaming_grey;
    }

    label {
        display: block;
        font-weight: 600;
        color: variables.$ocean_black;
        margin-bottom: 7px;
    }

    &--checkbox,
    &--radio {
        label {
            display: inline-block;
        }
    }

    &--radio {
        label {
            margin-right: 20px;
        }

        input {
            margin-right: 5px;
        }
    }

    &--required {
        position: relative;

        &:before {
            font-size: 14px;
            position: absolute;
            left: -12px;
            top: -2px;
            content: '*';
            color: variables.$buoy_red;
        }
    }

    .unit {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: variables.$light_teal;
    }

    &__inner {
        margin-bottom: 0;
    }

    &--multiple {
        display: flex;
        flex-wrap: wrap;

        .form-element__inner {
            flex: 1;
        }

        .form-element__main-field {
            flex: 1;

            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .form-element__tolerance {
            width: 80px;
            margin-left: -1px;
            margin-right: -1px;

            &:nth-child(even) {
                input {
                    //background-color: $gray_main;
                }
            }

            label {
                font-weight: normal;
                padding-left: 10px;
                position: relative;
                top: 7px;
            }

            input {
                font-size: 14px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &__subfield {
        position: relative;
        flex: 1;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        .unit {
            top: 10px;
            right: 10px;
        }
    }

    &--calculation {
        .calculation-symbol {
            position: absolute;
            left: 10px;
            top: 23px;
        }

        label {
            margin-left: 40px;
        }

        numeric-input {
            margin-left: 40px;
        }

        textarea {
            margin-left: 40px;
        }
    }
}

.form-section {
    margin-bottom: 66px;

    &--no-margin-below {
        margin-bottom: 0px;
    }

    .label {
        margin-bottom: 21px;
    }

    &--auto-cols {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 30px;
    }

    &--auto-cols--narrow {
        gap: 15px;
    }

    &--two-cols {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        >.form-element {
            width: calc(50% - calc(variables.$app_default_padding_horizontal / 2));

            @media (max-width: variables.$xs) {
                width: calc(50% - calc(variables.$app_default_padding_horizontal / 2));
            }

            @media (max-width: variables.$xxs) {
                width: 100%;
            }
        }
    }

    &--three-cols {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        >.form-element {
            width: calc(100% / 3 - 2 * #{variables.$app_default_padding_horizontal});

            @media (max-width: variables.$xs) {
                width: 100%;
            }
        }
    }

    &--four-cols {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        >.form-element {
            width: calc(100% / 4 - 3 * calc(variables.$app_default_padding_horizontal / 2));

            @media (max-width: variables.$xs) {
                width: 100%;
            }
        }
    }

    &--five-cols {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        >.form-element {
            width: calc(100% / 5 - 2 * #{variables.$app_default_padding_horizontal});

            @media (max-width: variables.$xs) {
                width: 100%;
            }
        }
    }

    &--bb {
        padding-bottom: 1em;
        margin-bottom: 50px;
        border-bottom: 1px solid variables.$foaming_grey;
    }

    &--extra-padding-left {
        padding-left: 40px;
    }

    &--less-margin-below {
        margin-bottom: 1em;
    }

    &--no-margin-below {
        margin-bottom: 0px;
    }

    .form-actions {
        width: 100%;
    }
}

.form-messages {
    margin: 1em 0;
}

.form-actions {
    margin-bottom: 1em;

    &--belowtable {
        margin-top: 1em;
    }

    &--extra-margin-above {
        margin-top: 2em;
    }

    @media (max-width: variables.$xs) {
        .button {
            display: block;
            margin-bottom: 10px;
        }
    }

    @media (max-width: variables.$xxs) {
        .button {
            width: 100%;
        }
    }
}

.form-element,
.calc-field {

    .unit,
    .form-element__input-prefix {
        font-size: 14px;
    }
}
