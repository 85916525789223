@use '../variables';
@use 'sass:color';

.alert {
  padding: variables.$spacing-4;
  border-radius: variables.$border-radius;
  display: flex;
  align-items: center;
  margin-bottom: variables.$spacing-4;

  &--warning {
    background-color: color.adjust(variables.$approved_yellow, $lightness: 20%);
    color: color.adjust(variables.$approved_yellow, $lightness: -18%);
  }

  &--error {
    background-color: color.adjust(variables.$approved_red, $lightness: 20%);
    color: color.adjust(variables.$approved_red, $lightness: -18%);
  }
}
