@use '../variables';
@use 'sass:color';

/*----------  Buttons  ----------*/
.button-group {
    display: flex;

    @media (max-width: variables.$xs) {
        flex-wrap: wrap;

        .button,
        progress-button,
        dropdown {
            width: 100%;
        }

        .button {
            border-color: #f2f2f2;
            margin-bottom: 10px;
        }
    }

    .button,
    progress-button {
        display: block;
        margin-right: 10px;
    }

    &--align-right {
        .button {
            margin-right: auto;
            margin-left: 10px;
        }
    }
}

.button {
    text-align: center;
    line-height: 1.4;
    display: inline-block;
    padding: 11px 35px;
    border: 1px solid black;
    transition: all 0.2s ease;
    border-radius: 4px;
    color: variables.$teal;
    min-height: 46px;
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: -2px;

    &:focus {
        outline-color: variables.$blue_medium;
        text-decoration: underline;
    }

    &:hover {
        background-color: transparent;
        border-color: color.adjust(variables.$blue_medium, $lightness: -8%);
    }

    &--smaller {
        padding: 6px 20px;
        min-height: 38px;
    }

    &--smallest {
        min-height: 30px;
        padding: 3px 15px;
        background-size: 14px;
    }

    &--primary {
        background-color: variables.$blue_medium;
        color: variables.$white;
        border-color: variables.$blue_medium;
        min-width: 160px;

        &:hover,
        &:focus {
            background-color: color.adjust(variables.$blue_medium, $lightness: -8%);
            border-color: color.adjust(variables.$blue_medium, $lightness: -8%);
        }

        &[disabled] {
            background: variables.$blue_light;
            border-color: variables.$blue_light;
            color: white;
        }
    }

    &--secondary {
        background-color: transparent;
        border-color: transparent;
        outline-color: variables.$blue_medium;
        min-width: 160px;

        &:hover {
            background-color: color.adjust(variables.$blue_medium, $lightness: -8%);
            border-color: color.adjust(variables.$blue_medium, $lightness: -8%);
            color: variables.$white;
        }

        &[disabled] {
            outline-color: transparent;
            background-color: variables.$gray_main;
            border-color: variables.$gray_main;
        }
    }

    &--dangerous {
        background-color: variables.$buoy_red;
        border-color: variables.$buoy_red;
        color: variables.$white;
    }

    &--warning {
        background-color: variables.$approved_yellow;
        border-color: variables.$approved_yellow;
        color: variables.$white;

        &:hover {
            background-color: color.adjust(variables.$approved_yellow, $lightness: -5%);
            border-color: variables.$approved_yellow;
        }
    }

    &--error {
        &:hover {
            background-color: variables.$soft_red_dark;
            border-color: variables.$soft_red_dark;
        }

        &[disabled] {
            background-color: variables.$soft_red_light;
            border-color: variables.$soft_red_light;
            color: variables.$soft_red;
        }

        background-color: variables.$soft_red;
        border-color: variables.$soft_red;
        color: variables.$white;

        svg {
            fill: variables.$white;
        }
    }

    &--ghost {
        border-color: transparent;
        background-color: transparent;

        &:hover,
        &:focus {
            outline-color: transparent;
            text-decoration: none;
            background-color: transparent;
            border-color: rgba(variables.$teal, 0.5);
        }
    }

    &--link {
        border-color: transparent;
        background-color: transparent;
        padding: 0;

        &:hover {
            border-color: transparent;
            outline-color: transparent;
            text-decoration: underline;
        }
    }

    &--new {
        @extend %button-with-icon-left;
        background-image: url(images/icon-plus.svg);
    }

    &--minus {
        @extend %button-with-icon-left;
        background-image: url(images/icon-minus.svg);
    }

    &--certificate {
        @extend %button-with-icon-left;
        background-image: url(images/icon-certificate.svg);
    }

    &--history {
        @extend %button-with-icon-left;
        background-image: url(images/icon-history.svg);
    }

    &--download {
        @extend %button-with-icon-left;
        background-image: url(images/icon-download.svg);
    }

    &--send {
        @extend %button-with-icon-left;
        background-image: url(images/icon-send.svg);
    }

    &--up {
        @extend %button-with-icon-left;
        background-image: url(images/icon-chrevron-up-teal.svg);
    }

    &--down {
        @extend %button-with-icon-left;
        background-image: url(images/icon-chrevron-down-teal.svg);
    }

    &--left {
        @extend %button-with-icon-left;
        background-image: url(images/icon-chrevron-left-teal.svg);
    }

    &--right {
        @extend %button-with-icon-left;
        background-image: url(images/icon-chrevron-right-teal.svg);
    }

    &--file {
        @extend %button-with-icon-left;
        background-image: url(images/icon-file.svg);
    }

    &--updatedoc {
        @extend %button-with-icon-left;
        background-image: url(images/icon-updatedoc.svg);
    }

    &--delete {
        @extend %button-with-icon-left;
        background-image: url(images/icon-delete.svg);
    }

    &--edit {
        @extend %button-with-icon-left;
        background-image: url(images/icon-edit.svg);
    }

    &--bars {
        @extend %button-with-icon-left;
        background-size: 18px;
        background-image: url(images/icon-bars.svg);
    }

    &--close {
        @extend %button-with-icon-left;
        background-image: url(images/icon-close.svg);
    }

    &--print {
        @extend %button-with-icon-left;
        background-image: url(images/icon-print.svg);
    }

    &--net {
        @extend %button-with-icon-left;
        background-image: url(images/icon-net.svg);
    }

    &--lock {
        @extend %button-with-icon-left;
        background-image: url(images/icon-lock.svg);
    }

    &--unlock {
        @extend %button-with-icon-left;
        background-image: url(images/icon-unlock.svg);
    }

    &--filter {
        @extend %button-with-icon-left;
        background-image: url(images/icon-filter.svg);
    }

    &--x {
        @extend %button-with-icon-left;
        background-image: url(images/icon-x.svg);
    }

    &--excel {
        @extend %button-with-icon-left;
        background-image: url(images/icon-excel.svg);
    }

    &--save {
        @extend %button-with-icon-left;
        background-image: url(images/icon-save.svg);
    }

    &--next {
        @extend %button-with-icon-right;
        background-image: url(images/icon-chrevron-right-white.svg);
    }

    &--prev {
        @extend %button-with-icon-left;
        background-image: url(images/icon-chrevron-left-teal.svg);
    }

    &--prev-primary {
        @extend %button-with-icon-left;
        background-image: url(images/icon-chrevron-left-white.svg);
    }

    &--copy {
        @extend %button-with-icon-left;
        background-image: url(images/icon-copy.svg);
    }

    &--file-download {
        @extend %button-with-icon-left;
        background-image: url(images/file-download.svg);
    }

    &--file-preview {
        @extend %button-with-icon-left;
        background-image: url(images/file-preview.svg);
    }

    &--icononly {
        @extend %button-with-icononly;
    }

    &--no-style {
        border: none;
        background-color: transparent;
        color: variables.$teal;
        padding: 0;
        min-height: auto;
        height: 26px;
        width: 26px;
        line-height: 1;
        text-decoration: underline;
        cursor: pointer;
    }

    &--icononly-small {
        padding-left: 21px !important;
        background-position: center;
        background-size: 14px;
        padding: 14px;
        min-height: 14px;
        height: 14px;
    }

    &--noborder {
        &:hover {
            border-color: transparent;
        }
    }

    &--underlined-hover {
        &:hover {
            text-decoration: underline;
        }
    }

    &--inlineedit {
        @extend %button-with-icon-left;
        background-image: url(images/icon-edit-light.svg);
        background-color: transparent;
        border-color: transparent;
        padding: 0px !important;
        margin-top: 5px;
        width: 30px;
        height: 20px;
        min-height: 20px;

        &:hover {
            background-image: url(images/icon-edit.svg);
            border-color: transparent;
        }
    }

    &--nominwidth {
        min-width: auto;
    }

    &--icon-right {
        @extend %button-with-icon-right;
    }

    &--inline-input {
        position: absolute;
        right: 0;
        height: 40px;
    }
}

%button-with-icon {
    background-repeat: no-repeat;
}

%button-with-icon-left {
    @extend %button-with-icon;
    padding-left: 40px;
    background-position: left 10px center;
}

%button-with-icon-right {
    @extend %button-with-icon;
    padding-right: 40px;
    background-position: right 10px center;
}

%button-with-icononly {
    padding-left: 20px;
    background-position: center;
}

button[disabled] {
    cursor: not-allowed;
    background-color: variables.$gray_main;
    border-color: variables.$gray_main;
    color: #858585;
    pointer-events: none;
    cursor: default;
}

.icon {
    background-repeat: no-repeat;

    &--lock {
        background-image: url(images/icon-info-locked.svg);
        display: inline-block;
        width: 20px;
        height: 18px;
    }
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    min-width: auto;

    color: variables.$blue_medium;

    padding: 4px 3px 4px 0;
    margin: 0;

    background: none;
    border: none;
    border-bottom: 1px solid transparent;

    transition: all 0.2s ease-in;

    &:active,
    &:focus {
        background: none;
        border-bottom: 1px solid transparent;
    }

    &:hover {
        background: none;
        color: variables.$blue_dark_d20;
        cursor: pointer;
        border-bottom: 1px solid variables.$blue_dark;
    }

    &.light {
        color: variables.$blue_light;

        &:hover {
            color: variables.$teal_grey;
            border-bottom: 1px solid variables.$blue_light;
        }
    }

    &.button--ok {
        color: variables.$pastel_green;

        &:hover {
            color: color.adjust(variables.$pastel_green, $lightness: 5%);
            border-bottom: 1px solid variables.$pastel_green;
        }
    }

    &.button--warning {
        color: color.adjust(variables.$approved_yellow, $lightness: -5%);

        &:hover {
            color: color.adjust(variables.$approved_yellow, $lightness: -10%);
            border-bottom: 1px solid variables.$approved_yellow;
        }
    }
}
