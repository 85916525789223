@use "../variables";

.table-filters-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 1rem;
    @media (max-width: variables.$xs) {
    }

    &--top,
    &--bottom {
        display: flex;
        width: 100%;
    }
    &--top {
        justify-content: space-between;
        gap: 1rem;

        .form-element {
            flex: 1 1 auto;
            min-width: 100px;
            max-width: 350px;
        }
        .table-filters__search {
            .form-element {
                min-width: 100%;
            }
            width: 100% !important;
        }

        @media (max-width: variables.$xs) {
            flex-direction: column-reverse;
            .form-element {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
    &--bottom {
        flex-wrap: wrap;
        > *,
        .table-filters__search {
            margin-right: 1rem;
            width: 100% !important;
            max-width: 350px !important;
        }
        @media (max-width: variables.$xs) {
            > *,
            .table-filters__search {
                max-width: 100% !important;
            }
        }
    }
}
