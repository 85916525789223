@use "../variables";

.table {
    .table-move-up-down-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .table-move-up-down__handler {
            transition: all 0.2s ease;
        }

        .table-move-up-down__handler {
            &:hover {
                cursor: pointer;
            }

            i {
                font-size: 16px !important;
            }

            &--up {
                &:hover {
                    transform: translateY(-2px);
                }
            }

            &--down {
                &:hover {
                    transform: translateY(2px);
                }
            }
        }
    }

    .table-add-button {
        background: variables.$pearl_grey;
        color: variables.$blue_dark;
        border: 1px solid variables.$blue_light;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -10px;
        bottom: -11px;
        z-index: 10;
        transition: all 0.3s ease;

        &:hover {
            cursor: pointer;
            color: white;
            background: variables.$blue_dark;
            border-color: variables.$blue_dark;
        }
    }
}
