@use 'sass:color';
/*----------  flexbox simple grid  ----------*/
$large: 1439px;
$b_large: 1440px;

$m: 1200px;
$b_m: 1201px;

$s: 959px;
$b_s: 960px;

$xs: 768px;
$b_xs: 769px;

$xxs: 540px;
$b_xxs: 541px;

// Blues
$blue_subtle_light: #f2f5fa;

$blue_dark: #0e4959;
$blue_dark_l5: #125668;
$blue_dark_d5: #003e52;
$blue_dark_d10: #003444;
$blue_dark_d20: #06232d;

$blue_medium: #437c8d;
$blue_medium_hover: #54aec9;

$blue_light: #a8c6ce;

// new colors:

$ocean_black: #06232d;

$dark_teal: #003e52;

$medium_teal: #0e4959;

$teal: #437c8d;

$light_teal: #a8c6ce;

$teal_grey: #d6e1e5;

$cloudy_grey: #ced4da;

$buoy_red: #c22927;
$deep_orange: #ff7f3e;
$light_orange: #ffd27f;
$soft_red: #c86c6c;
$soft_red_light: color.adjust($soft_red, $lightness: 15%);
$soft_red_dark: color.adjust($soft_red, $lightness: -10%);

$salmon_pink: #ff917c;

$net_green: #83c8bc;
$sea_grass_green: #c3dc93;
$foaming_grey: #e8eced;
$canvas_white: #fbfcfc;
$pearl_grey: #f7f8f9;
$boring_grey: #767878;

$white: #fff;

$venture_purple: #a886c6;
$jumpnet_blue: #22b2ca;

$service_blue: #4a90e2;

$service_order_green: #bff08d;
$pastel_green: #71c776;

// approvals
$approved_green: #7ed321;
$approved_yellow: #ffa500;
$approved_red: #c22927;

// Grays
$gray_main: #ebebeb;
$gray_light: #eaeaea;
$gray_lighter: #f8f8f8;
$gray_lightest: #fcfcfc;
// border: 1px solid #dbdbdb;

$gray_blue_lighter: #e9ecef;
$gray_blue_light: #ced4da;

$zebra_light: #eff2f3;
$gray_zebra: $zebra_light;
$gray_vertical_lines: #d8d8d8;

$orange: #ff917c;
$cyan: #83c8bc;

/*=============================================
=                    App                     =
=============================================*/

$app_default_padding_horizontal: 30px;
$app_default_padding_vertical: 30px;

$app_sidebar_padding_horizontal: 22px;
$app_sidebar_padding_vertical: 22px;

$app_default_header_height: 75px;

$border-radius: 6px;
$font-family-normal: 'Open Sans', sans-serif;
$font-family-title: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$spacing-1: 2px;
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 16px;
$spacing-5: 24px;

/*=============================================
=                    Grid                     =
=============================================*/
$soft_shadow: 0 2px 0.2em 0 #0000000f;
$default_shadow: 0px 4px 8px rgb(86 86 86 / 17%);

ux-dialog-body {
  transition: all 0.2s ease-in-out;
}

.search-box {
  transition: all 0.2s ease-in-out;
  position: relative;

  .search-box--loader {
    position: absolute;
    bottom: 30px;
    right: 5px;
    border-width: 2px;
  }

  .search-box--result {
    display: flex;
    flex-direction: column;
    /* gap: 4px; */
    transition: 0.2s ease-in-out;
    transition-property: background, color;
    min-width: 455px;

    .search-box--result__no-result {
      width: 100%;
      padding: 2rem;
      text-align: center;
      color: $cloudy_grey;
    }

    .search-box--result__item {
      transition-property: background, color;
      transition: 0.2s ease-in-out;
      display: flex;
      flex-direction: column;

      cursor: pointer;
      padding: 1rem;
      text-align: left;
      background-color: $gray_lightest;
      border: none;
      border-bottom: 1px dashed $light_teal;
      width: 100%;

      &__name,
      &__meta {
        color: $dark_teal;
      }

      .search-box--result__item__content {
        margin-bottom: 0.25rem;
        display: flex;
        justify-content: space-between;
      }

      .search-box--result__item__extra {
        &:last-child {
          margin-bottom: 0;
        }

        margin-bottom: 0.15rem;
        color: $boring_grey;
        font-style: italic;
      }

      &--focused,
      &:focus {
        background-color: $teal_grey;
      }
    }
  }
}
